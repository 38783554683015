import Accordion from "accordion-js";
import "slick-carousel";

export default {
  init() {
    if (jQuery("#submenu > li").hasClass("current_page_item")) {
      jQuery("#subsubmenu").appendTo("#menu-lvl3 .current_page_item");
    }

    if (document.querySelector(".accordion-container") !== null) {
      new Accordion(".accordion-container");
    }

    $(".actualites-int-slider").slick({
      lazyLoad: "ondemand",
      dots: true,
      infinite: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
