import Masonry from 'masonry-layout';
import '@fancyapps/fancybox';

export default {
  init() {
    const grid = document.querySelector('.galerie-container');
    const msnry = new Masonry(grid, {
      itemSelector: '.galerie-item',
      columnWidth: 280,
      gutter: 20
    });

    const gridVideo = document.querySelector('.galerie-container-video');
    const msnry_video = new Masonry(gridVideo, {
      itemSelector: '.galerie-item',
      columnWidth: 280,
      gutter: 20
    });
  },
  finalize() {}
};
