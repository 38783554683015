import "slick-carousel";
import common from "../routes/common";
export default {
  init() {
    jQuery(document).ready(() => {
      console.log("home");
      $(".slider-actualites").slick({
        lazyLoad: "ondemand",
        dots: true,
        infinite: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        appendDots: ".lien-slider",
      });
    });

    //un clic toggle responsive
    $("#toggleUnclick").click(function () {
      $("#un-clic__menu").toggleClass("visible");
      $("#toggleUnclick").toggleClass("rotate");
    });

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src =
        "//connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v2.9&appId=142191202511797";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    var windowsize = $(window).width();

    windowsize = $(window).width();
    if (windowsize < 1200) {
      jQuery(".facebook").appendTo(jQuery(".home__top"));
    }
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
