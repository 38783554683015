// Styles SCSS
import "../sass/app.scss";

import $ from "jquery";

// window.jQuery = $;
window.$ = $;

// Router
import Router from "./util/Router";
import common from "./routes/common";
import home from "./routes/home";
import page from "./routes/page";
import galerie from "./routes/galerie";
import informationsSurLesPlagesDoleron from "./routes/informationsSurLesPlagesDoleron";
//import associations from './routes/associations';

// SVG polyfiil
import "svgxuse";

// LazyLoad
import lazyLoadInit from "./lazyload-init";
lazyLoadInit();

// pushbar
import Pushbar from "./util/pushbar";

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** Home page */
  home,
  /** page */
  page,

  galerie,
  informationsSurLesPlagesDoleron,
});
/** Load Events */
jQuery(document).ready(() => routes.loadEvents());

const pushbar = new Pushbar({
  blur: true,
  overlay: true,
});
