export default {
  init() {
    // JavaScript to be fired on all pages
    if (jQuery("path")) {
      jQuery(".carto__container path").each(function () {
        var clas = jQuery(this).attr("class");
        console.log(clas);
        var clas1 = clas.split(" ")[1];
        jQuery(this).on("click", function () {
          jQuery(".area").removeClass("active");
          jQuery(".carto__container span").removeClass("active");
          jQuery(".communes__item").removeClass("active");
          jQuery(this).addClass("active");
          jQuery("." + clas1).addClass("active");
        });
      });
    }

    jQuery(".search__form-mots ul li ").each(function () {
      var a = jQuery(this);
      if (a.children().is(":empty")) a.remove();
    });

    $("li.ac").each(function () {
      var gravity = $(this).find(".gform_wrapper");
      var confirmation = $(this).find(".gform_confirmation_wrapper ");
      if (gravity.hasClass("gform_validation_error") === true) {
        //$('body').addClass('gform_validation_error');
        $(this).addClass("active");
      }
    });

    function nightMode() {
      if (localStorage.getItem("stylesheet")) {
        localStorage.clear();
        document.getElementById("night-css").setAttribute("href", "");
      } else {
        localStorage.clear();
        document.getElementById("grey-css").setAttribute("href", "");
        localStorage.setItem(
          "stylesheet",
          "/wp-content/themes/oleron/night.css"
        );
        document
          .getElementById("night-css")
          .setAttribute("href", localStorage.getItem("stylesheet"));
      }
    }

    function greyMode() {
      if (localStorage.getItem("stylesheetGrey")) {
        localStorage.clear();
        document.getElementById("grey-css").setAttribute("href", "");
      } else {
        localStorage.clear();
        document.getElementById("night-css").setAttribute("href", "");
        localStorage.setItem(
          "stylesheetGrey",
          "/wp-content/themes/oleron/grey.css"
        );
        document
          .getElementById("grey-css")
          .setAttribute("href", localStorage.getItem("stylesheetGrey"));
      }
    }

    document.getElementById("night-mode").addEventListener("click", nightMode);
    document.getElementById("grey-mode").addEventListener("click", greyMode);
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
