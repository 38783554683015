import { getBeachesByPostalCode, getBeachesByInseeCode } from "maplageinfo-sdk";
export default {
  init() {
    // JavaScript to be fired on all pages
    console.log("plages");
    const params = {
      username: "edith.pavan",
      password: "MnTNR52$",
    };

    function template(plage) {
      const div = document.getElementById("plages");
      for (let index = 0; index < plage.length; index++) {
        let affluance, affluanceIcon, flag, flagIcon, danger;

        if (plage[index].attendance_on_the_beach === "low") {
          affluance = "Affluence Faible";
          affluanceIcon = "#affluence-1";
        } else if (plage[index].attendance_on_the_beach === "average") {
          affluance = "Affluence Moyenne";
          affluanceIcon = "#affluence-2";
        } else if (plage[index].attendance_on_the_beach === "high") {
          affluance = "Affluence Forte";
          affluanceIcon = "#affluence-3";
        }

        if (plage[index].flag_color === "green") {
          flag = "Baignade surveillée <br> et absence de danger";
          flagIcon = "#drapeau-2";
        } else if (plage[index].flag_color === "yellow") {
          flag = "Baignade dangereuse <br> mais surveillée";
          flagIcon = "#drapeau";
        } else if (plage[index].flag_color === "red") {
          flag = "Baignade interdite";
          flagIcon = "#drapeau-1";
        }

        let updated =
          plage[index].inputsHistory.length > 0
            ? new Date(
                plage[index].inputsHistory[
                  plage[index].inputsHistory.length - 1
                ].createdAt
              )
            : "";
        console.log();

        div.innerHTML += `
        <div>
          <h4>
          <img class='arrow' src='/wp-content/themes/oleron/dist/images/long-arrow.svg'/>
          ${plage[index].name}
          ${
            updated !== ""
              ? "<span> Mis à jour le " +
                updated.toLocaleDateString("fr-FR") +
                " à " +
                updated.getHours() +
                "h" +
                updated.getMinutes() +
                "</span>"
              : ""
          }
          </h4>
          <div class='plage'>
            <div>
              <svg class="icon">
                <use xlink:href="/wp-content/themes/oleron/dist/images/plages-sprite.svg#temp"></use>
              </svg>
              <span class='temp'>${plage[index].water_temperature}°</span>
              Température <br> de l’eau
            </div>
            <div>
              <svg class="icon">
                <use xlink:href="/wp-content/themes/oleron/dist/images/plages-sprite.svg${affluanceIcon}"></use>
              </svg>
              ${affluance}
            </div>
            <div>
                        <svg class="icon">
                <use xlink:href="/wp-content/themes/oleron/dist/images/plages-sprite.svg${flagIcon}"></use>
              </svg>
              ${flag}
            </div>
          </div>
        </div>
        `;
      }
    }

    (async (params) => {
      const plages = {
        "Dolus-d’Oléron": await getBeachesByPostalCode(params, "17550"),
        "La Brée-les-Bains": await getBeachesByPostalCode(params, "17840"),
        "Le Château-d’Oléron": await getBeachesByPostalCode(params, "17480"),
        "Le Grand Village Plage": await getBeachesByInseeCode(params, "17485"),
        "Saint-Denis-d’Oléron": await getBeachesByPostalCode(params, "17650"),
        "Saint-Georges-d’Oléron": await getBeachesByPostalCode(params, "17190"),
        "Saint-Pierre-d’Oléron": await getBeachesByPostalCode(params, "17310"),
        "Saint-Trojan-les-Bains": await getBeachesByInseeCode(params, "17411"),
      };

      document.getElementById("loading").remove();
      for (let [key, value] of Object.entries(plages)) {
        const div = document.getElementById("plages");
        div.innerHTML += `<h3>${key}</h3>`;
        template(value);
      }
    })(params);
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
